import './Books.css'
import React, { useState, useEffect } from 'react'
import { Dialog } from 'primereact/dialog'
// import '../../../utils/utils.css'
import { galleriaAllBook } from '../../../constants/ImportPhoto'


export default function Books() {
  const [visible, setVisible] = useState(false)
  const [imgOpen01, setimgOpen01] = useState('Long-Poto-Start')
  useEffect(() => {
    setimgOpen01('Long-Poto')
  }, [])
  return (
    <div className="Book">
      <div className="Book_Сell">
        <div className="PhotoBook"></div>
        <div className="Description">
          <h1>Фотокниги.</h1>
          <h2>
            У вас прошла свадьба, юбилей, корпоратив или вы съездили с друзами
            на пикник, с ребенком в отпуск, с семьей в туристическую поездку
            отдохнули на море отсняли сотни фотографий. Показали малую их часть
            друзьям на телефоне со словами это смотреть, а это нет. Через
            какое-то время есть повод вспомнить о событии, но часть или все
            нечаянно потерли или просто не помните, где записали. Чтобы такого
            не произошло, хороший способ сохранить память в виде фотокниги.
            Фотокнига может стать отличным оригинальным подарком.
          </h2>
          {/* <a href="https://volsvik.com/wedding">ghghghgh</a> */}
        </div>
        <h2 className="DescriptionLong">
          Эта услуга для тех, кто занят важными делами, у кого не хватает
          времени на отбор и обработку фотографий, на разборки с шаблонными
          конструкторами фотокниг на сайтах для компоновки и верстки фотокниг
          самостоятельно. Вы ставите задачу скидываете имеющийся у вас исходный
          материал и получаете готовую фотокнигу. Стоимость от 50 у.е. Ниже
          примеры разворотов фотокниг. Если вам нужен оригинальный продукт в
          готовом виде, не откладывайте на завтра то, что можно сделать сейчас.
          Просто позвоните или напишите мне.
        </h2>
      </div>
      <div className="card flex justify-content-center">
        <div className="Galleri-MenuBook">
          <div
            className={imgOpen01}
            onClick={() => {
              setVisible(true)
              galleriaAllBook.number = 0
            }}
          >
            <img src={galleriaAllBook.photoIcon[0]} alt="" />
          </div>
          <div
            className={imgOpen01}
            onClick={() => {
              setVisible(true)
              galleriaAllBook.number = 1
            }}
          >
            <img src={galleriaAllBook.photoIcon[1]} alt="" />
          </div>
          <div
            className={imgOpen01}
            onClick={() => {
              setVisible(true)
              galleriaAllBook.number = 2
            }}
          >
            <img src={galleriaAllBook.photoIcon[2]} alt="" />
          </div>
        </div>
        <Dialog
          header={galleriaAllBook.header[galleriaAllBook.number]}
          visible={visible}
          style={{ width: '100vw', maxWidth: `${galleriaAllBook.width}` }}
          onHide={() => setVisible(false)}
        >
          {galleriaAllBook.galleria[galleriaAllBook.number].map(
            (item, index) => (
              <div key={index}>
                <img
                  src={item}
                  style={{
                    width: '100vw',
                    maxWidth: `${galleriaAllBook.widthCont}`,
                  }}
                  alt=""
                />
                <p className="mb-5"></p>
              </div>
            )
          )}
        </Dialog>
      </div>
    </div>
  )
}
